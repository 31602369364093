import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Spacer } from '../../spacer';
import { Typography } from '../../typography';

export interface FormFieldProps {
  label: string;
  fieldAs?: 'div' | 'fieldset';
  hideLabel?: boolean;
  htmlFor?: string;
  invalid?: boolean;
  errorMessage?: string;
  hintText?: string;
  disabled?: boolean;
  optional?: string;
  required?: boolean;
}

type StyledFormFieldProps = Pick<FormFieldProps, 'disabled'>;
type StyledFormFieldHeaderProps = Pick<FormFieldProps, 'hideLabel'>;

const StyledFormField = styled.div<StyledFormFieldProps>`
  ${({ disabled }) => css`
    // Disabled

    ${disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
  `};
`;

const StyledFormFieldHeader = styled.div<StyledFormFieldHeaderProps>`
  ${({ theme: { space }, hideLabel }) => css`
    ${!hideLabel &&
    css`
      margin-bottom: ${space.xxSmall};
    `}
  `};
`;

const StyledFormFieldLabel = styled(Typography)`
  ${() => css`
    font-weight: 700;
  `};
`;

const StyledFormFieldOptional = styled.span`
  ${() => css`
    font-weight: 400;
  `};
`;

const StyledFormError = styled(Typography)`
  ${({ theme: { space } }) => css`
    margin-top: ${space.small};
  `};
`;

const StyledRequired = styled.span`
  ${({ theme: { space, colors } }) => css`
    color: ${colors.systemDanger};
  `};
`;

export const FormField: FC<React.PropsWithChildren<FormFieldProps>> = ({
  label,
  fieldAs = 'div',
  hideLabel,
  htmlFor,
  children,
  invalid,
  errorMessage,
  disabled,
  hintText,
  optional,
  required,
  ...rest
}) => (
  <StyledFormField as={fieldAs} disabled={disabled} {...rest}>
    <StyledFormFieldHeader hideLabel={hideLabel}>
      <Spacer spacing="xSmall">
        <StyledFormFieldLabel
          variant="bodyMedium"
          htmlFor={htmlFor}
          component={fieldAs === 'fieldset' ? 'legend' : 'label'}
          visuallyHidden={hideLabel}
        >
          {label}{' '}
          {optional && (
            <StyledFormFieldOptional>{optional}</StyledFormFieldOptional>
          )}
          {required && <StyledRequired>*</StyledRequired>}
        </StyledFormFieldLabel>
        {hintText && (
          <Typography id={`${htmlFor || 'field'}-hint`} variant="bodyMedium">
            {hintText}
          </Typography>
        )}
      </Spacer>
    </StyledFormFieldHeader>
    {children}
    {invalid && (
      <StyledFormError variant="body" color="systemDanger">
        <Typography component="span" visuallyHidden>
          Error:
        </Typography>
        {errorMessage || 'Required'}
      </StyledFormError>
    )}
  </StyledFormField>
);
